$background-color: #000000;
$text-color: #FFFFFF;
$icon-color: #960000;


.footer-container {
    background-color: $background-color;
    color: $text-color;
    font-weight: 300;
}

.link-click {
    cursor: pointer;
}


.wrappper-container {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: auto;
    flex: 1;
    position: relative;
}

.center-container {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: normal;
}

.footer-logo {
    margin-top: 85px;
}

.links-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: baseline;
    margin-top: 0;
    margin-top: 30px;
    margin-bottom: 15px;
    width: auto;
    
}

.footer-link {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    
}

.footer-icon {
    margin-right: 10px;
    width: 15px;
    height: 15px;
}

.footer-icon-info {
    opacity: 0.8;
}

.footer-separator {

}

.footer-site {
    margin-bottom: 0;
}

.share-container {
    text-align: center;
    .share-sign-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
       .link-separator {
            height: 1px;
            width: 315px;
            background: linear-gradient(90deg, #AA0101 35.86%, rgba(170, 1, 1, 0) 93.62%);

            &:first-child {
                transform: rotate(180deg);
            }
        }
        .share-sign {
            margin: 0 10px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #808080;
        } 
    }
    .icon-container {
        margin-bottom: 30px;
        .icon-container a {
            display: inline-block;  
            .icon-in {
                margin-right: 15px;
            }
        }  
    }

    .icon-container a {
        display: inline-block;  
        .icon-in {
            margin-right: 15px;
        }
    }
}

.bottom-container {
    max-width: 100%;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;    
    line-height: 19px;
    box-sizing: border-box;
    padding: 0 30px 30px;
    background: linear-gradient(180deg, #000000 0%, rgba(21, 23, 29, 0) 100%), #15171D;
    color: #CCCCCC;
    .info-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 770px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #808080;
        .adress {
            @media screen and (max-width: 715px) {
                max-width: 70%;
                margin: 0 auto;
            }
            @media screen and (max-width: 530px) {
                max-width: 100%;
            }
        }
            
        .timetable {
            color: #cccccc;
            margin-top: 10px;
        }
        .schedule {
            margin-top: 10px;
        }
    }
}


@media screen and (max-width: 1020px) {
    .footer-logo {
        margin-top: 40px;
    }
}

@media screen and (max-width: 768px) {
    .bottom-container {
        .info-container {
            max-width: 750px;
        }
    }
}

@media screen and (max-width: 740px) {
    .bottom-container {
        .info-container {
            max-width: 750px;
        }
    }

}


@media screen and (max-width: 480px) {

    .form-container {
        min-height: 800px;
    }

    .footer-separator {
        background: linear-gradient(180deg #AA0101 43.66%, rgba(170, 1, 1, 0) 81.16%);
    }


    .icon-container {
        margin-bottom: 50px;
    }
}


@media screen and (max-width: 435px) {
    .center-container {
        flex-direction: column;
        justify-content: flex-start;
        .footer-separator {
            width: 375px;
            height: 1px;
            background: linear-gradient(90deg, rgba(170, 1, 1, 0) 0%, #AA0101 51.04%, rgba(170, 1, 1, 0) 100%);
            margin-top: 30px;
        }
    }

    .wrappper-container {
        align-items: flex-start;
        margin-left: 30px;
    }

    .share-container {
        box-sizing: border-box;
        padding: 0 30px;
        margin-top: 20px;
        .share-sign-container {
            justify-content: flex-start;
            margin-bottom: 20px;
            .link-separator {
                width: 100px;
            }
            .link-separator-left {
                display: none;
            }
            .share-sign {
                margin-left: 0;
            }
        }
        .icon-container {
            text-align: left;
        }
    }

    .bottom-container {
        background: linear-gradient(0deg, #0D0E0F, #0D0E0F);
        .info-container {
            padding-top: 20px;
            & div {
                text-align: left;
            }
        }
    }
    
}
