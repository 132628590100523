$footer-cookie: #21252E;
$button-footer: #D70000;
$button-hover: #FF2B2B;
$button-active: #BA0101;
$list-bg: #F2F2F2;

.modal-main {
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    position: relative;
}


.modal-wrapper {
    max-height: 405px;
    overflow-y: scroll;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffffff;
    
}

.modal-padding {
    min-height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    right: 2%;
    background-color: #ffffff;
    border-top-left-radius: 10px;
    z-index: 500;
}

.modal-center {
    padding: 45px 50px;
}

.cookie-title {
    margin-top: 10px;
    font-size: 18px;
    line-height: 100%; 
    font-weight: bold;
    margin-bottom: 10px;
}

.cookie-text {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 15px;
}


.cookie-footer {
    background-color: $footer-cookie;
    // position: absolute;
    // bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.footer-center {
    padding: 10px 0 10px 50px;
    display: flex;
    align-items: center;

}

.footer-cookie-text {
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}


.modal-button {
    margin: 20px;
    font-size: 20px;
    color: #FFFFFF;
    background-color: $button-footer;
    min-width: 229px;
    border-radius: 50px;
    padding: 10px 0;
    text-align: center;
    box-shadow: 0px 10px 20px rgba(250, 87, 58, 0.12);
    cursor: pointer;

    &:hover {
        background-color: $button-hover;
    }

    &:active {
        background-color: $button-active;
    }
}

.cookie-list-item {
    max-width: 465px;
    background-color: $list-bg;
    margin-bottom: 10px;
    border-radius: 7px;
}

.last-item {
    margin-bottom: 20px;
}

.cookie-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    



    .cookie-text {
        margin: 0;
        padding: 13px 15px;
    }
}


.cookie-arrow {
    padding: 10px 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    transition: transform 0.2s ease-in;
    transform: rotate(0deg)
}


.cookie-arrow.turn  {
    transform: rotate(-90deg)
}


.cookie-list-details {
    font-size: 12px;
    padding: 0 15px 13px;
    display: block;
}

.closed {
    display: none;
}

.modal-main {
    .modal-wrapper {
        .modal-center {
            a {
                color: #0077bc;
            }
            .modal-underline {
                text-decoration: underline;
            }
            .header {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 2.4em;
                text-align: left;
                color: #000000;
                opacity: 0.6;
                margin: 15px 0 0;
            }
            .sub-header {
                font-weight: bold;
            }

        }
    }
}

@media screen and (max-width: 769px) {
    .footer-cookie-text {
        font-size: 14px;
    }

    .modal-button {
        margin: 20px;
        font-size: 14px;
        color: #FFFFFF;
        background-color: $button-footer;
        min-width: 179px;
        border-radius: 50px;
        padding: 10px 0;
        text-align: center;
        box-shadow: 0px 10px 20px rgba(250, 87, 58, 0.12);
    }
}

@media screen and (max-width: 480px) {

    .modal-button {
        margin: 10px;
    }
    .modal-main {
        .modal-wrapper {
          .modal-center {
            padding: 30px;

            .header {
                margin: 25px 0 0;   
                }
            }  
        }
    }

    

    .footer-center {
        flex-direction: column;
        padding: 20px 30px 10px 30px;
    }

    .footer-cookie-text {
        text-align: center;
    }
}
