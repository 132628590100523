.loader-container {
    width: 100vw;
    height: 100vh;
    background-color: #21252E;
    z-index: 10000;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    position: relative;
}

.loading{
    width: 180px;
    height: 180px;
    box-sizing: border-box;
    border-radius: 50%;
    border-top: 10px solid #D70000;
    animation: a1 2s linear infinite;
  }
  
  .loading::before,.loading::after{
    content: '';
    width: 180px;
    height: 180px;
    position: absolute;
    left: 0;
    top: -10px;
    box-sizing: border-box;
    border-radius: 50%;
  }
  
  .loading::before{
    border-top: 10px solid #40D00D;;
    transform: rotate(120deg);
  }
  
  .loading::after{
    border-top: 10px solid #FFFFFF;
    transform: rotate(240deg);
  }
  
  .loader-container img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    line-height: 200px;
  }
  
  @keyframes a1 {
    to{
      transform: rotate(360deg);
    }
  }
  
  @keyframes a2 {
    to{
      transform: rotate(-360deg);
    }
  }

  // Modal styles
  .styles_modal__gNwvD {
    padding: 0;
    background-color: transparent;
    border-radius: 15px;
  }

  .styles_closeButton__20ID4 {
    position: absolute;
    top: -14px;
    right: -8px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: #F2F2F2;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    z-index: 500;

      &:hover {
        // background-color: #C4C4C4;


        .styles_closeIcon__1QwbI {
          transform: rotate(90deg)
        }
      }
  }

  .styles_closeIcon__1QwbI {
    transition: 0.3s ease-in-out;
    width: 15px;
    height: 15px;
    margin: auto;
  }

  @media screen and (max-width: 720px) {
    .loading,
    .loading::before,
    .loading::after {
      width: 150px;
      height: 150px;
    }

    .loading{
      border-top: 8px solid #D70000;
    }

    .loading::before{
      border-top: 8px solid #40D00D;
      top: -8px;

    }
    
    .loading::after{
      border-top: 8px solid #FFFFFF;
      top: -8px;

    }

    .loader-container img {
      width: 40px;
    }
  }



  @media screen and (max-width: 480px) {
    .loading,
    .loading::before,
    .loading::after {
      width: 100px;
      height: 100px;
    }

    .loading{
      border-top: 8px solid #D70000;
    }

    .loading::before{
      border-top: 8px solid #40D00D;
      top: -8px;

    }
    
    .loading::after{
      border-top: 8px solid #FFFFFF;
      top: -8px;

    }
  }
