
$dark-color: #21252E;
$red-color: #D70000;
$form-bg: #F5F5F5;
$red-color-hover: #FF2B2B;
$red-color-active: #BA0101;

.img-tu {
    position: absolute;
    width: 80%;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)

}
.fb-mobile-box .form-error {
    font-size: 10px;
    height: 16px;
}
.invisibleCaptcha{
    visibility: hidden;
    height: 0;
}

.fb-mobile {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50.5%;
    transform: translate(-50%);


    .form-input-holder {
        box-shadow: none;
        margin-bottom: 20px;
    }


    .fb-mobile-title {
        font-family: 'Roboto', sans-serif;
        color: $dark-color;
        font-size: 40px;
        line-height: 100%;
        text-align: center;
        width: 90%;
        margin: 100px 0 30px;
        font-weight: 700;
    }

    .fb-mobile-descr {
        color: $red-color;
        font-size: 24px;
        font-weight: 700;
    }

}

.fb-mobile-box {
    margin-top: 30px;


    .form-field {
        width: 288px;
        height: 56px;
        box-shadow: none;
        background-color: $form-bg;
        font-size: 18px;
        border-radius: 50px !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: none;
    

        &:focus {
            background-color: #fff;
            border: 3px solid $form-bg;
    
        }
    }

    .form-button {
        width: 286px;
        height: 60px;
        box-shadow: none;
        background-color: $red-color;
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        text-transform: uppercase;
        display: flex;
        font-weight: bold;
        align-content: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        -webkit-touch-callout: none; 
          -webkit-user-select: none;
           -khtml-user-select: none; 
             -moz-user-select: none; 
              -ms-user-select: none; 
                  user-select: none;
    
        &:hover {
            background-color: $red-color-hover;
        }
    
        &:active {
            background-color: $red-color-active;
        }
}
}

.fb-mobile-check {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50.5%;
    transform: translate(-50%);
    .title-check{
        font-family: 'Roboto', sans-serif;
        color: $dark-color;
        font-size: 40px;
        line-height: 100%;
        text-align: center;
        margin: 100px 0 60px 0;
        font-weight: 700;
    }
    .captcha-container {
        .info-check-invisible {
            display: none;
        }
        .captcha-error {
            background-color: #B34A4A;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #ffffff;
            max-width: 99.5%;
            margin-top: -2px;
        }
    }
    
    .personal-information {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 474px;
        margin: 30px 0;
        margin-bottom: 87px;
        .checkbox-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 26px 0 13px;
            .info-check {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
                &:checked ~ .checkmark-checked {
                    display: block;
                }
            }
            .checkmark {
                display: block;
                background: #FFFFFF;
                border: 1px solid #CCCCCC;
                box-sizing: border-box;
                border-radius: 7px;
                width: 28px;
                height: 28px;
                &:hover {
                    background-color: #eee;
                }
            }
            .checkmark-checked {
                position: absolute;
                display: none;
            }
        }
    
        .info-text {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            color: #21252E;
            text-align: left;
            font-size: 12px;
            line-height: 15px;
            .cookie-policy {
                text-decoration: none;
                color: #0077bc;
                cursor: pointer;
            }   
        }
    }
    .form-button {
        width: 100%;
        height: 60px;
        box-shadow: none;
        background-color: $red-color;
        color: #fff;
        font-size: 18px;
        text-transform: uppercase;
        display: flex;
        font-weight: bold;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-right: 0;
        cursor: pointer;
        -webkit-touch-callout: none; 
          -webkit-user-select: none;
           -khtml-user-select: none; 
             -moz-user-select: none; 
              -ms-user-select: none; 
                  user-select: none;
    
        &:hover {
            background-color: $red-color-hover;
        }
    
        &:active {
            background-color: $red-color-active;
        }
    }
}

@media screen and (max-width: 1060px) {
    
    .fb-mobile {

       .fb-mobile-title {
            font-size: 32px;
            margin-top: 90px;
        }

        .form-input-holder {
            margin-bottom: 15px;
        }


        .fb-mobile-descr {
            font-size: 19px;
            margin-top: 20px;
        }

        .form-box {
            margin-top: 20px;
        }

    }

    .fb-mobile-check {
        .title-check {
            margin-top: 60px;
            margin-bottom: 30px;
        }
        .captcha-container {
            transform: scale(0.8);
            margin-bottom: 20px;
        }
        .personal-information {
            max-width: 80%;
            margin: 0 auto;
            .checkbox-container {
                margin-left: 9px;
            }
        }
        .form-button {
            max-width: 100%;
            margin: 30px auto;
        }
    }




    .fb-mobile-box {
        margin-top: 45px;
    
        // .form-button {
        //     width: 250px;
        //     height: 55px;
        // }
    
        .form-field {
            width: 230px;
            height: 50px;
            font-size: 16px;
        }

        .form-error {
            font-size: 10px;
        }

    }

}

@media screen and (max-width: 945px) {
    .fb-mobile {

        .fb-mobile-title {
             font-size: 28px;
             margin-top: -15px;
         }
 
 
         .fb-mobile-descr {
             font-size: 17px;
             margin-top: 15px;
         }
 
     }
 
     .fb-mobile-box {
         margin-top: 30px;
     
     
        .form-field {
            width: 200px;
            height: 45px;
            font-size: 14px;
        }

        .form-error {
        font-size: 8px;
        }
    }
    .fb-mobile-check {
        margin-top: -270px;
        .title-check {
            margin-top: 70px;
            font-size: 35px;
            margin-bottom: 30px;
        }
        .captcha-container {
            transform: scale(0.72);
            margin-bottom: 20px;
        }
        .personal-information {
            max-width: 70%;
            margin: 0 auto;
            
            .info-text {
              font-size: 10px;  
            }
            .checkbox-container {
                margin-left: 9px;
            }
        }
        .form-button {
            max-width: 100%;
            margin: 30px auto;
        }
    }
}
