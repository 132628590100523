$bg-cookie-color: #21252E;
$cookie-button  : #D70000;
$button-hover   : #FF2B2B;
$button-active  : #BA0101;


.cookie-banner {
    background-color: $bg-cookie-color;
    position        : fixed;
    bottom          : 0;
    left            : 0;
    width           : 100%;
    z-index         : 1000;
    height          : 80px;
    display         : flex;
    align-items     : center;

    @media screen and (max-width: 768px) {
        height: 100px;
    }
}

.cookie-wrapper {
    max-width      : 1070px;
    margin         : 0 auto;
    padding        : 10px;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    color          : #fff;
    font-size      : 14px;

    @media screen and (max-width: 1024px) {
        max-width: 916px;
    }

}

.cookie-policy {
    text-decoration: underline;
    cursor         : pointer;

    &:hover {
        color: #D70000;
    }
}

.cookie-buttons {
    margin-left: 40px;
}

.ck-btn {
    background-color: $cookie-button;
    border          : none;
    color           : #fff;
    text-transform  : uppercase;
    font-size       : 20px;
    border-radius   : 20px;
    margin          : 5px;
    text-align      : center;
    width           : 104px;
    height          : 40px;
    font-weight     : bold;
    padding         : 5px 0;
    line-height     : 120%;
    cursor          : pointer;

    &:hover {
        background-color: $button-hover;
    }

    &:active {
        background-color: $button-active;
    }
}

@media screen and (max-width: 1020px) {
    .cookie-wrapper {
        width  : 100%;
        padding: 20px 30px;
    }
}

@media screen and (max-width: 768px) {
    .cookie-wrapper {
        width  : 100%;
        padding: 20px 40px;

        .cb-info {
            max-width: 597px;
        }
    }
}

@media screen and (max-width: 480px) {

    .cookie-banner {
        align-items: flex-start;
    }

    .cookie-wrapper {
        padding       : 20px;
        font-size     : 12px;
        flex-direction: row-reverse;
        align-items   : flex-start;
    }

    .cb-info {
        margin-left: 20px;
    }

    .cookie-buttons {
        margin-left: 0;
    }

    .ck-btn {
        width    : 76px;
        height   : 30px;
        font-size: 16px;
        margin   : 0;
        padding  : 6px 0;
    }
}

@media screen and (max-width: 375px) {

    .cookie-banner {
        height: 50px;
        align-items: center;

        .cookie-wrapper {
            align-items: center;
            .cb-info {
                font-size: 8px;
            }
        }
    }
}
