.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 48px;
    height: 24px;
    background: #363A42;
    border-radius: 100px;
    position: relative;
    /* transition: background-color .2s; */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 1px;
    left: 2px;
    width: 22px;
    height: 22px;
    border-radius: 45px;
    /* transition: 0.2s; */
    background: #CC0101;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}


.react-switch-new {
    display: none;
}

@media screen and (max-width: 902px) {
    .react-switch-button {
        width: 18px !important;
        height: 18px !important;
    }

    .react-switch-label {
        width: 40px !important;
        height: 20px !important;
    }
}

@media screen and (max-width: 634px) {
    .react-switch-label {
        background: #21252E;
    }
}

@media screen and (max-width: 480px) { 
    .react-switch-label {
        background: #21252E;
    }

    .react-switch-label {
        width: 30px !important;
        height: 15px !important;
    }

    .react-switch-button {
        width: 12px !important;
        height: 12px !important; 
    }
}


/* @media screen and (max-width: 375px) {
    .react-switch-label {
        width: 24px !important;
        height: 14px !important;
    }

    .react-switch-button {
        width: 12px !important;
        height: 12px !important;
    }
} */