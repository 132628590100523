$logo: url('../../assets/logo.svg');
$main-color: #FFFFFF;
$hover-color: #D70000;
$button-color: #D70000;
$button-hover: #FF2B2B;
$button-active: #BA0101;
$background-color:  #21252E;
$logo-descr: #999999;
$logo-descr-span: #375AD7;
$logo-descr-fixed:  #CDCDCD;

.header-container {
    background-color: transparent;
}

.header-center {
    display: flex;
    height: 80px;
    max-width: 1162px;
    margin: 0 auto;
    align-items: center;
    color: $main-color;
    justify-content: space-between;
    font-style: normal;
    font-weight: normal;
}

.header-logo {
    margin-left: 22px;
    position: relative;

    .header-logo-img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    }

    .logo-descr {
        position: absolute;
        top: 34px;
        left: 53px;
        font-size: 14px;
        color: $logo-descr;
        font-weight: 300;
        white-space: nowrap;

        .logo-descr-span {
            color: $logo-descr-span;
        }
    }
}

.header-menu {
    display: flex;
    font-size: 18px;
    font-weight: 300;
    margin-right: 50px;
    align-items: center;



    .header-menu-nav {
        display: flex;
    }

    .header-menu-contact {
        margin-left: 61px;
    }

}


.header-menu-lgs {
    display: flex;
    margin-right: 80px;
    align-items: center;
    color: #FFFFFF;

    .header-menu-lg {
        cursor: pointer;
    }

    .lg-it {
        margin-right: 10px;
    }

    .lg-en {
        margin-left: 11px;
    }

    .active-lng {
        color: #D70000;
    }

    .header-menu-lg-separator {
        cursor: default;
        width: 1px;
        height: 17px;
        background-color: #fff;
    }
}

.header-menu-nav {

    .header-menu-nav-item:hover {
        color: $hover-color;
        cursor: pointer;
    }

    .nav-services, 
    .nav-projects {
        margin-right: 40px;
    }

}

    .header-menu-contact {
        cursor: pointer;
        font-size: 17px;
        font-weight: bold;
        width: 102px;
        background-color: $button-color;
        border-radius: 50px;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        color: #fff;
        // box-shadow: 0 7px 14px $button-color;

        &:hover {
            background-color: $button-hover;
        }

        &:active {
            background-color: $button-active;
        }

    }

    .header-menu .cont-it {
        width: 120px;
        margin-left: 40px;
    }

    // .fixed-nav {
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     background-color: $background-color;
    //     z-index: 1000;
    // }

    .fixed-nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: $main-color;
        z-index: 1000;
        box-shadow: 0px 4px 30px rgba(33, 37, 46, 0.1);

        .header-menu-contact {
            cursor: pointer;
            font-size: 17px;
            font-weight: bold;
            width: 102px;
            background-color: $button-color;
            border-radius: 50px;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            color: #fff;
            // box-shadow: 0 7px 14px $button-color;
    
            &:hover {
                background-color: $button-hover;
            }
    
            &:active {
                background-color: $button-active;
            }

        }

        .active-lng {
            color: $hover-color;
        }

        .header-menu-lg-separator {
            background-color: #333;
        }


        .header-menu-nav {
            color: $background-color;
        }

        .header-menu-lgs {
            color: $background-color;
        }

        .header-logo-img {
            filter: none;
        }

        .header-menu .cont-it {
            width: 120px;
            margin-left: 40px;
        }


        .react-switch-label {
            background: rgb(233, 233, 233);
        }
        

    }


    @media screen and (max-width: 1020px) {
        .header-center {
            width: 100%;
        }

        .header-logo-img{
            width: 183.11px;
        }

        .header-menu-lgs {
            margin-left: 50px;
            margin-right: 50px;
        }

        .header-menu .header-menu-contact {
            margin-left: 50px;
        }

        
        .header-menu {
            margin-right: 50px;
        }

        .header-menu .cont-it {
            margin-left: 29px;
        }

        .fixed-nav .header-menu .cont-it {
            margin-left: 29px;
        }

        .header-logo {

            .logo-descr {
                position: absolute;
                top: 30px;
                left: 47px;
                font-size: 12px;
            }
        }
    }

    @media screen and (max-width: 900px) {
        .header-logo {

            .logo-descr {
                position: absolute;
                top: 20px;
                left: 34px;
                font-size: 10px;
            }
        }

        .header-logo-img {
            width: 130.79px;
        }
    }


    @media screen and (max-width: 828px) {
        .header-menu-nav,
        .header-menu-contact,
        .header-menu-lgs {
            font-size: 16px;
        }

        .header-menu-nav .nav-projects,
        .header-menu-nav .nav-services {
            margin-right: 20px;
        }

        .header-menu {
            margin-right: 50px;
        }

        .header-menu-lgs {
            margin-right: 40px;
        }

        .header-center {
            padding-right: 0;
        }

    }

    @media screen and (max-width: 768px) {

        .fixed-nav {
            
            .header-menu-contact .cont-it {
                width: 112px;
            }
        }

    }



    @media screen and (max-width: 745px) {
       .header-menu {
           margin-right: 0px;

        .header-menu-nav {
            display: none;
        }

        .header-menu-contact {
            display: none;
        }

        .header-menu-lg {
            height: 40px;
            margin-right: 0;
            margin-left: 0;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            font-size: 16px;
        }

        .lg-it {
            padding: 0 5px 0 30px;
        }

        .lg-en {
            padding: 0 30px 0 5px;
        }

        .header-menu-lgs {
            display: flex;
            align-items: center;
            margin: 0;
        }

    
       }
       .fixed-nav {
            
            .header-menu-contact {
                display: block;
                margin-right: 17px;
                margin-left: 18px;
            }

            .header-menu-lgs {
                margin-right: 10px;
                background-color: transparent;
            }

            .header-menu-contact {
                width: auto;
                padding-left: 21px;
                padding-right: 21px;
            }

            .header-menu .cont-it {
                margin-left: 0px;
            }
        }

        


    }



    @media screen and (max-width: 480px) {
        .header-center {
            height: 72px;
        }

        .header-logo-img {
            width: 104.63px;
        }

        .header-logo {

            .logo-descr {
                position: absolute;
                top: 15px;
                left: 26px;
            }
        }

        .header-logo {
            margin-left: 20px;
        }

        .header-menu-nav {
            font-size: 14px;
        }

        .fixed-nav {

            .header-menu-contact {
                font-size: 14px;
                padding-top: 8.5px;
                padding-bottom: 8.5px;
            }

            .header-menu .cont-it {
                margin-left: 0px;
                width: 112px;
            }
            .header-menu-contact {
                margin-left: 21px;
            }
        }

        .header-menu-lgs {
            margin: 0 30px;
        } 

        .header-menu .header-menu-lg {
            font-size: 16px;
        }

    }

   
    @media screen and (max-width: 450px) {
        .header-logo {

            .logo-descr {
                position: absolute;
                top: 20px;
                left: 0px;
                font-size: 10px;
            }
        }
    }

    @media screen and (max-width: 416px) {
        .fixed-nav {
            
            .header-menu-contact {
                margin-left: 20px;
                font-size: 12px;
                padding-top: 8px;
                padding-bottom: 8px;
                padding-left: 12px;
                padding-right: 12px;
                font-size: 12px;
            }

            .header-menu .cont-it {
                width: 90px;
            }
        }
    }

    @media screen and (max-width: 375px) {
        .header-center {
            height: 56px;
        }

        .header-menu .header-menu-lg {
            height: 24px;
        }

        .header-menu-lgs .header-menu-lg-separator {
            height: 16px;
        }

        .fixed-nav {
            
            .header-menu-contact {
                margin-left: 20px;
                padding-left: 12px;
                padding-right: 12px;
                font-size: 12px;
            }

        }
    }

    @media screen and (max-width: 375px) {
        .fixed-nav .header-menu-lgs {
            margin-left: 15px;
        }
        

        .fixed-nav {

            .header-menu .cont-it {
                margin-left: 0px;
            }

            .header-menu-lgs {
                margin: 0;
            }
            
            .header-menu-contact {
                margin-left: 23px;
            }

        }

        .header-menu {
            .lg-it {
                padding: 0 5px 0 20px;
            }
    
            .lg-en {
                padding: 0 20px 0 5px;
            }
        }
      

    }

    @media screen and (max-width: 343px) {
        
        .fixed-nav {
            
            .header-menu .cont-it {
                width: 80px;
            }


            .header-menu-contact {
                margin-left: 13px;
            }
    
        }

    }
