$red-color: #FD2600;
$text-color: #818181;
$red-main-color: #D70000;

.container-lp {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 65px;
}

.title-lp {
    margin-top: 110px;
    display: flex;
    flex-direction: column;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 38px;
    letter-spacing: 7px;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

    .title-separator-lp{
        width: 100px;
        height: 2px;
        margin: 10px 0;
        background-color: $red-main-color;
    }
}

.project-item {
    display: flex;
    max-width: 1100px;
    margin-bottom: 140px;
    
    &:nth-child(4) {
        margin-bottom: 0;
    }
}

.phone-holder-left {
    margin-left: 30px;
}

.phone-holder-right {
    margin-right: 30px;
}

.phone-holder.phone-small-size {
    display: none;
}

.phone-holder {
    position: relative;

    .img-holder {
        position: relative;
    }

    .phone-video {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 75%;
        z-index: 20;
    }

    .phone-holder-img {
        position: relative;
        z-index: 10;
    }

    .vertical-red {
        opacity: 0;
        z-index: 1;
        background-color: $red-main-color;
        height: 2px;
        width: 464px;
        position: absolute;
        top: 65px;


        &.left-vertical {
            left: -100px;
        }

        &.right-vertical {
            left: 40px;
        }
    }

    .horizontal-black {
        opacity: 0;
        z-index: 1;
        background-color: #8b8b8b;
        height: 464px;
        width: 2px;
        position: absolute;
        top: 10px;


        &.right-horizontal {
            right: -15px;
        }

        &.horizontal-left {
            left: -15px;
        }
    }
}

.project-info {
    line-height: 22px;

    .fat-logo {
        padding: 15px 0 10px;
    }

    .project-name {
        margin-top: 25px;
    }

    .project-purpose {
        font-size: 16px;
        margin:10px 0 20px;
    }

    .project-description,
    .description {
        font-size: 16px;
        color: $text-color;
        font-weight: lighter;
    }

    .title-bold {
        margin-top: 20px;
        font-size: 20px;
        margin-bottom: 5px;
        font-weight: bold;
    }
}

.item-left {
    margin-left: 75px;
    margin-right: 30px;
}

.item-right {
    margin-right: 75px;
    margin-left: 30px;
    text-align: right;
}


@media screen and (max-width: 1020px) {

    .project-item {
        width: 100vw;
        margin-bottom: 120px;
        
        &:nth-child(4) {
            margin-bottom: 0;
        }
    }

    .title-lp {
        font-size: 32px;
        line-height: 100%;
    }

    .phone-holder img {
        width: 345px;
        height: 628px;
    }
    

    .item-left {
        margin-left: 55px;
    }

    .item-right {
        margin-right: 55px;
    }

    .phone-holder {

        .horizontal-black {
            height: 352px;
            top: -20px;

        }

        .horizontal-black.right-horizontal {
            right: 10px;
        }


        .horizontal-black.horizontal-left {
            left: 10px;
        }

    }

    .phone-holder .vertical-red.left-vertical {
        left: -130px;
    }

    .phone-holder .vertical-red.right-vertical {
        left: 10px;
    }

    .logo-realty-small {
        width: 310px;
    }

}


@media screen and (max-width: 768px) {

    .phone-holder img {
        width: 278.5px;
        height: 513.8px;
    }

    .project-info {

        .project-description,
        .description {
            font-size: 14px;
        }

        .project-purpose {
            margin: 0 0 10px 0;
        }

        .title-bold {
            margin-top: 15px;
        }

    }

    .item-left {
        margin-left: 40px;
    }

    .item-right {
        margin-right: 40px;
    }

    .phone-holder .vertical-red.left-vertical {
        left: -170px;
    }

    .phone-holder .vertical-red.right-vertical {
        left: -10px;
    }

    .logo-realty-small {
        width: 270px
    }

    .title-lp {
        font-size: 28px;
        letter-spacing: 4px;

        .title-separator-lp {
            width: 80px;
        }
    }

}

@media screen and (max-width: 635px) {

    .title-lp {
        margin-bottom: 70px;
    }

    .phone-holder.phone-big-size {
            display: none
    }

    .phone-holder.phone-small-size {
        display: block;
        margin: 0;
        // width: 272.5px;
        // height: 503px;

        .horizontal-black {
            height: 384px;
            top: 35px;
            left: 0px
        }

        .vertical-red {
            width: 352px;
            left: -40px;
            top: 100px;
        }
    }

    .phone-holder.phone-holder-right.phone-small-size {
        .horizontal-black {
            height: 384px;
            top: 35px;
            left: 280px
        }

        .vertical-red {
            width: 352px;
            left: -20px;
            top: 100px;
        }
    }

    .project-info {
        width: 94vw;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        margin: auto;

            .project-description,
            .description {
                font-size: 14px;
            }

            .project-purpose {
                margin-top: 8px;
                margin-bottom: 10px;
            }
    }

    .project-item {
        margin-bottom: 70px;
        
        &:nth-child(4) {
            margin-bottom: 0;
        }
    }

}


@media screen and (max-width: 480px) { 
    .title-lp {
        font-size: 24px;

        .title-separator-lp {
            width: 68px;
        }
    }


}


@media screen and (max-width: 375px) { 
    .title-lp {
        font-size: 20px;

        .title-separator-lp {
            width: 52px;
        }
    }


}
