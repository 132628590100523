@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');

html, body {
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
}


* {
    box-sizing: border-box;
}

 textarea:focus, input:focus{
    outline: none;
}

*:focus {
    outline: none;
}

input {
    padding: 0 10px;
}

