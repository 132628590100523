$text-color: #333333;
$red-color: #D70000;
$svg-color: #21252E;
$telephone-text-color: #FFFFFF;
$black-color: #21252E;


.container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: center;
}

.small-phone-text {
    display: none;
}

.title {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 38px;
    letter-spacing: 7px;
    justify-content: center;
    align-items: center;
    line-height: auto;

    .title-separator {
        width: 100px;
        height: 2px;
        margin: 10px 0;
        background-color: $red-color;
    }
}

.phone-cont {
    margin-top: 50px;
    max-width: 1100px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    .summary-img {
        width: 34px;
        height: 434px;
    }
}

.phone-text {
    margin: 0 24px;
    max-width: 207px;
    line-height: 21px;

    .phone-text-title {
        font-weight: bold;
        font-size: 18px;  
    }

    .phone-text-ceparator {
        width: 50px;
        height: 1px;
        background-color: $red-color;
        margin: 10px 0;
    }

    .phone-text-description {
        font-size: 14px;
        color: $text-color;
        opacity: 0.5;
    }

    .phone-img {
        margin-bottom: -20px;
    }

    .summary-img {
        flex: 1 0 0px;
    }

}


.icons-container {
    max-width: 640px;
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    

    .icons-box {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        text-align: center;
        cursor: default;
        padding: 0 10px;


        &:nth-child(1) {
            margin-right: 100px;
        }

        &:nth-child(2) {
            margin-right: 96px;
        }

        &:nth-child(3) {
            margin-right: 63px;
        }

        .icon-holder {
            position: relative;
            width: 60px;
            height: 60px;
         
        }

        .icons-box-icon {
            opacity: 0.8;
            position: absolute;
            top: 0;
            left: 0;
            width: 60px;
            height: 60px;
            transition: transform 0.6s ease;

        }

        .icons-box-title {
            margin-top: 25px;
            opacity: 0.5;
            font-size: 20px;
            font-weight: 300;
            color: #323232;
            z-index: 20;
            transition: opacity 0.6s ease;
        }

        .icon-holder:before {
            content: " ";
            position: absolute;
            top: -20px;
            left: -80px;
            width: 25px;
            height: 200%;
            background: rgba(255, 255, 255, 0.3);
            z-index: 20;
            transform: skew(-30deg);
            transition: left 0.8s ease;
        }

        .shadow {
            position: absolute;
            width: 5px;
            height: 5px;
            background: radial-gradient(50% 50% at 50% 50%, #C4C4C4 0%, rgba(196, 196, 196, 0) 100%);
            bottom: -15px;
            left: 48%;
            border-radius: 50%;
            transform: translate(-50%);
            opacity: 0;
            z-index: 30;
            transition: all 0.4s ease;
        }


    }

    .icons-box:hover {
        .icon-holder:before  {
            left: 90px;
        }

        .icon-holder {
            overflow: visible;
        }

       .icons-box-icon {
            transform: scale(1.15);
        }

        .shadow {
            opacity: 1;
            transform: scaleX(11);
        }

        .icons-box-title {
            opacity: 1;
        }
    }
}

//Phone blocks styles


.phone-box {
    position: relative;

}



.phone-block-holder {
    width: 50%;
    margin: 0 auto;


}

.phone-block {
    position: absolute;
    height: 6.7%;
    left: 50%;
    transform: translate(-50%);
    width: 42.3%;
    cursor: default;
}

.phone-box:hover .change-on-hover path {
    fill: #4B4E55;
}

.phone-box:hover .stroke-on-hover path {
    fill: #fff;
}

.red-arrows {
    opacity: 0;
    animation: animate1 8s linear infinite;

}

$class: red-arrows;

@for $i from 1 through 32 {
  .#{$class}:nth-child(#{$i}) {
    animation-delay: ($i/20+$i/20)+s
  }
}
  
  @keyframes animate1 {
    0% {
      opacity: 0;
    }

    3% {
        opacity: 1;
    }

    5% {
        opacity: 0.5;
    }
    8% {
        opacity: 0.2;
    }
    10% {
      opacity:0;
    }
  }

  .red-arrows-2 {
    opacity: 0;
    animation: animate2 8s linear infinite;

}

$class: red-arrows-2;

@for $i from 1 through 32 {
  .#{$class}:nth-child(#{$i}) {
    animation-delay: ($i/20+$i/20)+s
  }
}
  
  @keyframes animate2 {
    50% {
      opacity: 0;
    }

    53% {
        opacity: 1;
    }

    55% {
        opacity: 0.5;
    }
    58% {
        opacity: 0.2;
    }
    60% {
      opacity:0;
    }
  }


.white-arrows {
    opacity: 0;
    animation: animateWh 8s linear infinite;

}

$class: white-arrows;

@for $i from 1 through 27 {
  .#{$class}:nth-child(#{$i}) {
    animation-delay: ($i/20+$i/20)+s
  }
}
  
  @keyframes animateWh {
      50% {
        opacity: 0;
      }
  
      53% {
          opacity: 1;
      }
  
      55% {
          opacity: 0.5;
      }
      58% {
          opacity: 0.2;
      }
      60% {
        opacity:0;
      }
   
  }

  .white-arrows-2 {
    opacity: 0;
    animation: animateWh2 8s linear infinite;

}

$class: white-arrows-2;

@for $i from 1 through 27 {
  .#{$class}:nth-child(#{$i}) {
    animation-delay: ($i/20+$i/20)+s
  }
}
  
  @keyframes animateWh2 {
    0% {
        opacity: 0;
      }
  
      3% {
          opacity: 1;
      }
  
      5% {
          opacity: 0.5;
      }
      8% {
          opacity: 0.3;
      }
      10% {
        opacity:0;
      }
  }

  
.phone-block-title {
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}



.yellow-path-container {
    position: relative;
    //   z-index: 10;
  }


.phone-block:hover .phone-block-img{
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
} 

.pb-1 {
    top: 18%;
    transition: transform 0.5s ease;
}

.pb-1 .phone-block-bg{
    background-color: $red-color;
    width: 96.5%;
    height: 100%;

}

.pb-2 {
    top: 26.7%;
    transition: transform 0.5s ease;

    .phone-block-bg {
        background-color: $black-color;
        width: 85%;
        height: 100%;
        border-radius: 26px 0px 0px 26px;
        position: absolute;
        right: 3.2%;
    }
}

.pb-3 {
    top: 35.45%;
    transition: transform 1s ease;

    .phone-block-bg {
        background-color: $red-color;
        width: 75%;
        height: 100%;
        position: absolute;
        right: 0.2%;
        border-radius: 26px 0px 0px 26px;
    }
}

.pb-4 {
    top: 44.2%;
    transition: transform 1s ease;

    .phone-block-bg {
        background-color:  $black-color;
        position: absolute;
        left: 3.3%;
        height: 104%;
        width: 93.5%;
    }
}

.pb-5 {
    top: 53.2%;
    transition: transform 1s ease;

    .phone-block-bg {
        height: 104%;
        width: 93.5%;
        position: absolute;
        left: 3.3%;
        background-color:  $black-color;
    }
}

.pb-6 {
    top: 62%;
    transition: transform 1s ease;

    .phone-block-bg {
        width: 88%;
        position: absolute;
        left: 3.7%;
        height: 104%;
        background-color:  $black-color;
        border-radius: 0px 18px 18px 0px;
    }
}

.pb-7 {
    top: 71%;
    transition: transform 1s ease;

    .phone-block-bg {
        width: 96.1%;
        position: absolute;
        left: 3.7%;
        height: 104%;
        background-color:  $red-color;
    }
}

.phone-block:hover .phone-block-bg {
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
}

.pb-1:hover {
    transform: translate(-53%, -10%)
}

.pb-2:hover {
    transform: translate(-44%, -10%)
}

.pb-3:hover {
    transform: translate(-46%, -10%)
}

.pb-4:hover {
    transform: translate(-53%, -10%)
}

.pb-5:hover {
    transform: translate(-45%, -10%)
}

.pb-6:hover {
    transform: translate(-53%, -10%)
}

.pb-7:hover {
    transform: translate(-45%, -10%)
}


@media screen and (max-width: 1020px) {


    .icons-container {


        .icons-box {

            .icons-box-icon {
                height: 50px;
                width: 50px;
            }
    

            &:nth-child(1) {
                margin-right: 78.07px;
            }
    
            &:nth-child(2) {
                margin-right: 73.07px;
            }
    
            &:nth-child(3) {
                margin-right: 45px;
            }

            .icons-box-title {
                font-size : 18px;
            }

            .icons-box-title {
                margin-top: 20px;
            }

            .icon-holder {
                position: relative;
                width: 50px;
                height: 50px;
            }

            .icon-holder:before {
                width: 20px;
            }

        } 
        
    }

    .phone-cont  .left-img,
    .phone-cont  .right-img {
        width: 28px;
        height: auto;
    }

    .phone-cont .summary-img {
        height: auto;
        width: auto;
    }

    .phone-img {
        width: 413.33px;
        height: auto;
    }

    .phone-text {
        margin: 0 10px;
    }

    .title {
        margin-top: 100px;
        display: flex;
        font-size: 32px;
        letter-spacing: 5px;
    
        .title-separator {
            width: 78px;
            height: 2px;
            margin: 10px 0;
            background-color: $red-color;
        }
    }

    .pb-4 {
    top: 44.2%;
    transition: transform 1s ease;

    .phone-block-bg {
        background-color:  $black-color;
        position: absolute;
        left: 3.3%;
        height: 104.5%;
        width: 93.5%;
    }
}

    .pb-5 {
        top: 53%;
        transition: transform 1s ease;

        .phone-block-bg {
            height: 104.5%;
            width: 93.5%;
            position: absolute;
            left: 3.2%;
            background-color:  $black-color;
        }
    }



}

@media screen and (max-width: 800px) {
    .phone-text-big, .left-img {
        display: none;
    }

    .icons-container .icons-box .icon-holder:before {
        left: -45px;
    }

    .small-phone-text {
        margin-top: -20px;
        display: block;
        margin-bottom: 20px;
    }

    .phone-cont .right-img {
        height: auto;
        width: 28px;
    }

    .phone-cont {
        margin-top: 45px;
    }

    .icons-container {

        .icons-box {

            &:nth-child(1) {
                margin-right: 53.07px;
            }
    
            &:nth-child(2) {
                margin-right: 50.07px;
            }
    
            &:nth-child(3) {
                margin-right: 27px;
            }

        }

        
    }

    .title {
    
    
        .title-separator {
            width: 78px;
            height: 2px;
            margin: 8px 0;
            background-color: $red-color;
        }
    }

    .phone-block-title {
        font-size: 16px;
    }

}

@media screen and (max-width: 768px) {
    .title {
        font-size: 28px;
        letter-spacing: 4px;
    }

    
}

@media screen and (max-width: 653px) {

    .phone-cont {
        margin-top: 30px;

        
        .right-img {
            display: none;
        }

        .small-container {
            display: none;
        }
    }

    .icons-container {

        .icons-box {

            &:nth-child(1) {
                margin-right: 30px;
            }
    
            &:nth-child(2) {
                margin-right: 30px;
            }
    
            &:nth-child(3) {
                margin-right: 17px;
            }

            .icons-box-title {
                font-size: 16px;
            }

            .icon-holder:before {
                left: -45px;
                height: 150%;
            }

        }

        .icons-box:hover {
            .icon-holder:before  {
                left: 80px;
                height: 160%;
        }
    }

      
    }

}

@media screen and (max-width: 599px) {
    .title {
        margin-top: 70px;
    }
}

@media screen and (max-width: 480px) {
    
    .phone-block-title {
        font-size: 16px;
    }

    .icons-container .icons-box {

        &:nth-child(1) {
            margin-right: 24px;
        }

        &:nth-child(2) {
            margin-right: 24px;
        }

        &:nth-child(3) {
            margin-right: 20px;
        }

    }


    .icons-container .icons-box .icons-box-title {
        font-size: 12px;
    }


    .phone-box {
      
        margin: 0 20px;

        .phone-img {
            width: 100%;
            height: auto;
        }
    }

    .icons-container {
        margin-top: 30px;
    }

    .title {
        font-size: 24px;
        margin-top: 70px;
    }



}

@media screen and (max-width: 400px) {
.icons-container .icons-box {
    position: relative;
}

    .icons-container .icons-box .icons-box-title {
        font-size: 10px;
        position: absolute;
        top: 100%;
    }

    .icons-container .icons-box .icons-box-icon {
        width: 40px;
        height: 40px;
       
    }



    .icons-container .icons-box .icon-holder {
        position: relative;
        width: 40px;
        height: 40px;
    }



     .icons-container .icons-box .icon-holder:before {
        width: 15px;
        left: -30px;
     }
     .icons-container  .icons-box:hover {
        .icon-holder:before  {
            left: 60px;
    }
}

}

@media screen and (max-width: 380px) {
    .icons-container .icons-box {

        .icons-box-icon {
            width: 40px;
            height: 40px;
        }

        &:nth-child(1) {
            margin-right: 15px;
        }

        &:nth-child(2) {
            margin-right: 15px;
        }

        &:nth-child(3) {
            margin-right: 15px;
        }

    }

}

@media screen and (max-width: 375px) {
    .icons-container {
        max-width: 300px;
    }
    .title {
        margin-top: 50px;
        font-size: 20px;
    }

    .phone-block-title {
        font-size: 12px;
    }
}
