.st0 {
  fill: none;
  stroke: #DB281A;
  stroke-width: 3;
}

.st1 {
  fill: #DB281A;
  stroke: #DB281A;
  stroke-width: 3;
}

.line-green {
  stroke: #00D63D;
  stroke-width: 3;
}

// .line-greed {
//   stroke: #DB281A;
//   opacity: 0.2;
//   stroke-width: 2;
// }