$red-color-text: #D70000;
$white-color-text: #FFFFFF;
$background-color: #960000;
$button-hover: #FF2B2B;
$button-active: #BA0101;

// $background-photo: url('../../assets/background.png');
$background-phone: url('../../assets/MainView/Union.svg');
$background-small: url('../../assets/MainView/bg-small.svg');

.red-container {
    background-color: $background-color;
    padding-bottom: 10px;
    box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3);

    .grey-container {
        // background: $background-photo;
        background-size: 100% 100%;
        border-bottom-right-radius: 200px;
        background-repeat: no-repeat;

    }
}

.small-size-bg {
    display: none;
}

.phone-triangle-sm,
.phone-little-circle-sm {
    display: none;
}

.content-container {
    margin: 75px auto 0;
    color: $white-color-text;
    display: flex;
    justify-content: center;
}

.phone-container {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 402px;
    height: 720px;


    .phone-img {
        position: relative;
        z-index: 10;
    }

    .img-abs {
        position: absolute;
        z-index: 9;
    }

    .phone-circle {
        z-index: 1;
        position: absolute;
        top: 150px;
        left: -90px;
    }

    .phone-triangle {
        position: absolute;
        left: -110px;
        top: 80px;
    }

    .phone-little-circle {
        position: absolute;
        left: -60px;
        bottom: 130px;
    }
}

.info-container {
    margin-left: 52px;
    margin-top: 15px;
}




.info-box {

    .info-box-title-create {
        font-size: 48px;
        font-weight: bold;
        line-height: 100%;
    }

    .create2 {
        text-transform: uppercase;
        margin-bottom: 90px;
    }

    .info-box-title-apps {
        font-size: 100px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 102px;
        color: $red-color-text;

    }

    .info-box-description {
        font-size: 20px;
        margin-top: 30px;
        font-weight: 300;
    }

    .info-box-button {
        cursor: pointer;
        margin: 30px 0 46px;
        width: 240px;
        padding: 22px 0;
        background-color: $red-color-text;
        border-radius: 50px;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;

        &:hover {
            background-color: $button-hover;
        }

        &:active {
            background-color: $button-active;
        }

    }

    .btn-it {
        width: 363px;
    }

}

.stats-box {
    margin-top: 84px;
    display: flex;
    margin-right: 40px;

    .stats-box-item {
        margin-right: 32px;
        max-width: 202px;
        font-size: 18px;
        opacity: 0.5;
        font-weight: 300;
        display: flex;
        flex-direction: column;

        span {
            font-size: 36px;
            font-weight: bold;
        }
    }
}

//arrows

.arrow-and-scroll {
    position: relative;
    margin-top: 65px;
    height: 80px;
}

.arrow{
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%);
}
.arrow span{
    display: block;
    width: 12px;
    height: 12px;
    margin: 2px;
    border-right: solid;
    border-bottom: solid;
    border-width: 2px;
    border-color: $red-color-text;
    transform: rotate(45deg);
    animation: animate 2s infinite;
}

.arrow span:nth-child(2){
    animation-delay: 0.4s;
}
.arrow span:nth-child(3){
    animation-delay: 0.8s;
}

@keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(0px,0px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(10px, 10px);
    }
}

//mouse

.scroll-downs {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translate(-50%);
  }
  .mousey {
    padding-top: 5px;
    width: 25px;
    height: 40px;
    border: 1px solid $red-color-text;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
  }
  .scroller {
    left: 50%;
    width: 5px;
    height: 8px;
    margin: 0 auto;
    border-radius: 55%;
    border: 1px solid $red-color-text;
    animation-name: scroll;
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
  }
  @keyframes scroll {
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px);  opacity: 0;}
  }


  @media screen and (max-width: 1060px) {

    .phone-container {
        width: 340px;
        height: 600.95px;

        .phone-circle {
            left: 50px;
            width: 333px;
            top: 110px;
        }
    
        .phone-triangle {
            position: absolute;
            top: 30px;
            left: 340px;
            width: 18px;
        }
    
        .phone-little-circle {
            position: absolute;
            left: 370px;
            bottom: 70px;
            width: 16px;
        }

        .phone-img {
            width: 340px;
            margin-bottom: 0;
            margin-top: -8px;
        }

    }

    .info-container {
        margin-top: 10px;
    }

    .info-box  {
        
        .info-box-title-apps {
            font-size: 90px;
            line-height: 90px;
        }
    
        .info-box-title-create {
            font-size: 36px;
        }


        .info-box-description {
            margin-top: 15px;
        }

        .info-box-button {
            margin-top: 20px;
        }

        .btn-it {
            width: 283px;
            padding: 12.5px 0;
        }
    }

    .stats-box {
        margin-top: 42px;

        .stats-box-item {
            font-size: 16px;
            margin-right: 20px;
        }

        .stats-box-item-num {
            font-size: 36px;
        }
    }

  }


  @media screen and (max-width: 945px) {

      .main-container .content-container .info-container {
          margin-left: 30px;
      }

      .phone-container {
          justify-content: center;
          align-content: center;
          align-items: center;
          vertical-align: middle;
          width: 290px;
          height: auto;

        .phone-img {
            width: 290px;
            height: 521.4px;
            margin-bottom: 10px;
        }



        .phone-circle {
            left: -70px;
            top: 55px;
        }
    
        .phone-triangle {
            position: absolute;
            left: 220px;
            top: -25px;
            width: 25px;
            height: 25px;
        }
    
        .phone-little-circle {
            position: absolute;
            left: 255px;
            bottom: 20px;
        }
    }

    .red-container .grey-container {
        border-bottom-right-radius: 150px;
    }

    .content-container {
        margin: 60px auto 0;
    }


    .info-box  {
        margin-top: -10px;
        min-width: 378.5px;
        
        .info-box-title-apps {
            font-size: 64px;
            line-height: 80px;
        }
    
        .info-box-title-create {
            font-size: 30px;
            margin-top: 0px;
        }

        .info-box-description {
            font-size: 18px;
            margin-top: 20px;
        }

        .info-box-button {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .create2 {
            margin-bottom: 60px;
        }
    }

    .stats-box {
        margin-right: 0;

        .stats-box-item {
            font-size: 12px;
            max-width: 100px;
            margin-right: 15px;
        }

        .stats-box-item span {
            font-size: 24px;
        }
    }

    .scroll-downs {
        display: none;
    }

    .arrow-and-scroll {
        margin-top: -30px;
    }

    .arrow span {
        width: 10px;
        height: 10px;
    }


  }

@media screen and (max-width: 698px) {
    .stats-box .stats-box-item {
        font-size: 12px;
        // max-width: 80px;
        margin-right: 10px;
    }

    .red-container .grey-container {
        border-bottom-right-radius: 150px;
    }


    .main-container .content-container .info-container {
        margin-left: 0;
    }

    .phone-container {
        margin-left: -30px;

        .phone-circle {
            left: -85px;
        }
    }

    .img-abs {
        left: -15px;
    }

    .arrow-and-scroll {
        margin-top: -20px;
    }

    .info-box {
        min-width: auto;
    }

    .main-container .content-container .info-container {
        margin-left: 0px;
    }

    .info-box .info-box-title-apps {
        width: 330px;
        font-size: 55px;
        line-height: 60px;
    }

    .info-box .info-box-description {
        margin-top: 10px;
    }

}

@media screen and (max-width: 634px) {

    .info-container {
        flex-direction: column;
        margin-top: 30px;
    }

    .phone-container {
        display: none
    }

    .red-container .grey-container {
        border-bottom-right-radius: 0;
    }

    .phone-triangle-sm,
    .phone-little-circle-sm {
        display: block;
        position: absolute;
        width: 15.39px;
        height: 15.39px;
    }

    .stats-box {
        width: 80%;
        margin: 0 auto 130px;
        height: 80px;


        .stats-box-item {
            max-width: none;
            display: flex;
            flex-direction: column;
            flex: 1 1 0;
            align-content: center;
            align-items: center;
            text-align: center;
        }
    }

    .info-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 50px;

        .info-box-title-create {
            font-size: 30px;
            line-height: 30px;
        }

        .create2 {
            text-transform: none;
            font-variant: small-caps;
            margin-bottom: 80px;
        }

        .info-box-title-apps {
            font-size: 72px;
            line-height: 100%;
            width: auto;
        }

        .info-box-description {
            line-height: 100%;
            font-size: 16px;
            margin-top: 0;
        }

        .info-box-button {
            font-size: 14px;
            // padding: 
        }

        .btn-it {
            width: 310px;
            padding: 22px 0;
        }
    }

    .small-size-bg {
        display: block;
        max-width: 440px;
        max-height: 440px;

        .small-size-bg-img {
            width: 100%;
            height: auto;
        }
    }

    .info-container {
        height: auto;
        display: flex;
        position: relative;
    }

    // .phone-triangle-sm {
    //     right: 110px;
    //     top: 30px;
    // }

    // .phone-little-circle-sm {
    //     bottom: 395px;
    //     left: 85px;
    // }
    .phone-triangle-sm,
    .phone-little-circle-sm {
        display: none;
    }

    .content-container {
        margin-top: 0;
        position: relative; 
    }


    .red-container .grey-container {
        // background-image: $background-small;
        background-size: cover;
        background-position: center top;
    }

    .red-container {
        background-color: transparent;
        box-shadow: none;
    }

    .arrow-and-scroll {
        margin-top: -60px;

        .arrow {
            bottom: 65%;
        }

        .arrow span {
            width: 7px;
            height: 7px;
            border-color: #FFFFFF;
            border-width: 2px;
        }
    }

    @keyframes animate {
        0%{
            opacity: 0;
            transform: rotate(45deg) translate(-5px,-5px);
        }
        50%{
            opacity: 1;
        }
        100%{
            opacity: 0;
            transform: rotate(45deg) translate(0px, 0px);
        }
    }

}

@media screen and (max-width: 495px)  {


    .stats-box {
        width: 90%;
        margin: 0 auto 150px;
    }

}


@media screen and (max-width: 450px) {
    .info-box {

        .info-box-title-apps {
            font-size: 56px;
        }

        .info-box-title-create {
            font-size: 24px;
            line-height: 100%;
        }

        .create2 {
            margin-bottom: 62px;
        }
    }

    .stats-box {
        margin: 0 auto 100px;
        height: 100px;

        .stats-box-item {
            font-size: 12px;

            span {
                font-size: 20px;
            }
        }

  
    }

    .arrow-and-scroll {
        .arrow {
            bottom: 55%;
        }
    }

    // .phone-triangle-sm,
    // .phone-little-circle-sm {
    //     width: 14.05px;
    //     height: 14.05px;
    // }

    // .phone-triangle-sm {
    //     top: 20px;
    //     right: 120px;
    // }

    // .phone-little-circle-sm  {
    //     position: fixed;
    //     bottom: 460px;
    //     left: 100px;
    // }


    .info-box .btn-it {
        padding: 13.5px 0;
        width: 240px;
    }
}

@media screen and (max-width: 375px) {
    .info-box {
        a {
            height: 90px;
        }
    }
}
