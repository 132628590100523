$red-color: #D70000;
$red-hover: #FF2B2B;
$red-active: #BA0101;

.charts-pos {
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center; 
    top: 25%;
    left: 50%;
    transform: translate(-50%);
    z-index: 10;
    width: 80%;
}

.wave-svg {
    position: absolute;
    top: 55%;
    left: 2%;
    // width: 23%;
    z-index: 10;
    width: 100%;

    svg {
        width: 286px;
        padding-right: 10px;
    }
}

.dash-img {
    z-index: 10;
    position: absolute;
    width: 73%;
    left: 50%;
    transform: translate(-50%);
    top: 90px;
}

#task-start {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: absolute;
    bottom: 17.1%;
    left: 50.5%;
    font-size: 18px;
    transform: translate(-50%);
    text-transform: uppercase;
    background: $red-color;
    border-radius: 30px;
    outline: none;
    width: 286px;
    height: 60px;
    border: none;
    font-weight: 300;
    z-index: 10;
    cursor: pointer;
    color: #fff;
    font-weight: bold;

    &:hover {
        background-color: $red-hover;
    }

    &:active {
        background-color: $red-active;
    }



}

// .dashboard-img {
//     width: 100%;
//     height: 100%;
// }

@media screen and (max-width: 1260px) {
    .wave-svg {

        width: 100%;

        svg {
            padding-right: 10px;
            // height: 9vw;
        }
    }


}

@media screen and (max-width: 1060px) {
    .wave-svg {
        top: 52%;
        width: 100%;

        svg {
            padding-right: 10px;
            width: 240px;
        }

    }

    #task-start {
        width: 230px;
        height: 50px;
        font-size: 16px;
        bottom: 17.8%;
    }


    .dash-img {
        top: 70px;
    }
}


@media screen and (max-width: 945px) {
    #task-start {
        bottom: 17.8%;
        height: 45px;
        width: 200px !important;
        font-size: 14px;
    }

    .phone-container {
        width: 290px;
        height: 494.33px;
    }

    .wave-svg {
        top: 48%;
        width: 100%;

        svg {
            padding-right: 10px;
            // height: 9vw;
            width: 210px;
        }
    }


    .dash-img {
        top: 45px;
    }


    .charts-pos {
        top: 20%;
    }
    
}

