$background-color: #21252E;
$title-color: #FFFFFF;
$red-color: #D70000;
$star-bg: rgba(255, 159, 159, 0.1);
$bright-red: #FD2600;

.slider-container {
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
}


.reviews-header {
  // padding-top: 163px;
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  // font-size: 72px;
  // line-height: 107px;
  text-align: center;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 0;
}

.slider-header {
  margin-top: 50px;
  
}


.reviews-text {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  text-transform: uppercase;

  color: #FFFFFF;
}

/*.footer-review */
.pagination-my {
  margin-top: 30px;
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  /*margin-bottom: 20px;*/
}

.page-footer {
  width: 6px;
  height: 6px;
  margin-left: 7px;
  margin-right: 7px;
  border-radius: 50%;
  background: #333333;
  opacity: 0.5;
}

.page-footer:hover {
  background-color: $red-color;
  cursor: pointer;
  opacity: 1;
}

.selected {
  opacity: 1;
  width: 35px;
  height: 8px;
  background: $red-color;
  border-radius: 5px;

}

.slider-description {
  display: flex;
  align-items: baseline;
}
/*.footer-review .selected:hover {*/
/*  width: 26px;*/
/*  height: 6px;*/

/*  background: #fce517;*/
/*  border-radius: 5px;*/
/*}*/
.footer-stars-container {
  background-color: $star-bg;
  width: 100%;
  margin-top: 17px;
  margin-bottom: 36px;
  padding-bottom: 8px;
}

.footer-stars {
  width: 71%;
  height: 67px;
  color: #fff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding-top: 1%;
}

.footer-text-block {
  width: 500px;
  min-height: 220px;
  position: relative;
  margin-bottom: 40px;
}

.footer-text-head {
  margin-bottom: 17px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  color: #FFFFFF;
}

.footer-text-descr {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  color: #FFFFFF;
}

.fead-icon {
  width: 83px;
  height: 83px;
  margin-right: 17px;
}

.fead-icon-img {
  width: 100%;
}

.fead-head {
  /*width: 400px;*/

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 42px;
  color: $title-color;
}

.footer-stars .mark-1 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  margin-right: 10px;
  color: $red-color;
  width: 47.8px;
}

.slider-text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #FFFFFF;
  margin-right: 5px;
}

.footer-stars .mark-2 {
  font-weight: bold;
  font-family:'Roboto', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: normal;
  color: $red-color;
  margin-top: 5px;
}

.footer-stars .star {
  margin-left: 5px;
  /*margin-right: 10px;*/
  width: 16px;
  height: 16px;
  background-image: url('../../assets/Slider/Star.svg');
  background-position: center;
  background-size: contain;

}


.footer-stars .star-half,
.star-empty {
  margin-left: 5px;
  width: 16px;
  height: 16px;
  background-image: url('../../assets/Slider/EmptyStar.svg');
  background-position: center;
  position: relative;
  background-size: contain;
}

.footer-stars .star-half {
  background-size: contain;
  margin-left: 4.54px;
  width: 16px;
  height: 16px;
}

.star-empty {
  background-image: url('../../assets/Slider/EmptyStar.svg');
  background-size: contain;
}

.arrow-left,
.arrow-right {
  width: 30px;
  height: 60px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

  &:hover {
    filter: invert(9%) sepia(84%) saturate(7491%) hue-rotate(6deg) brightness(95%) contrast(112%);
  }
}

.arrow-left {
  // margin-right: 6vw;
  background-image: url('../../assets/Slider/left.svg');
  background-repeat: no-repeat;
}

.arrow-right {
  // margin-left: 6vw;
  background-image: url('../../assets/Slider/right.svg');
  background-repeat: no-repeat;
}


.footer-review-outer {
  // background-image: url('../../images/slider-background.svg');
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}


.footer-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 43px;
  width: 700px;
  position: relative;
  background-color: $background-color;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
}


.fs-small {
  display: none;
}

.anim-text-left {
  animation: moveLeft 0.5s;
}

.anim-text-right {
  animation: moveRight 0.5s;
}

@keyframes moveLeft {
  from {
    left: 80px;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes moveRight {
  from {
    left: -80px;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

@media screen and (max-width: 1750px) {
  .slider-container {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .pagination-my {
    margin-top: 20px;
  }
}


@media screen and (max-width: 906px) {
  .footer-text-head {
    margin-bottom: 20px;
  }

  .arrow-right, .arrow-left {
    top: 48% !important;
  }
  .footer-review-outer {
    background-image: none;
    position: relative;
  }

  .footer-review {
    position: initial;
    top: 0;
  }


  // .arrow-left {
  //   left: 8%;
  //   z-index: 7;
  //   top: 50%;
  //   position: absolute;
  //   // background-image: url('../../images/left-yellow.svg');
  // }

  // .arrow-right {
  //   position: absolute;
  //   right: 8%;
  //   top: 50%;
  //   // background-image: url('../../images/right-yellow.svg');
  // }

  .footer-stars {
    width: 500px;
  }

  .footer-stars-container {
    width: 100%;
  }

}

@media screen and (max-width: 804px) {
  .arrow-left,
  .arrow-right {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .footer-review-outer {
    background: initial;
    margin-bottom: -40px;
  }

  .swipe-container {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .pagination-my {
    margin-top: 35px;
    margin-bottom: 50px;
  }

  .footer-text-block {
    width: 80%;
    margin: 0 auto 30px;
  }

  .slider-awards {
    width: 100% !important;
    justify-content: space-evenly !important;
  }

  .footer-stars {
    width: 100%;
  }

  .arrow-left,
  .arrow-right {
    display: none;
  }

  .page-footer {
    width: 8px;
    height: 8px;
  }

  .selected {
    width: 35px;
  }

  .slider-header {
    width: 94% !important;
    margin-left: 100px; //add
  }


  .footer-text-descr {
    font-size: 16px;
  }

  .footer-review {
    width:90%;
    top: -42px !important;
  }

  .fead-head {
    font-size: 24px;
    line-height: 28px;
  }


  .fs-big .slider-description :nth-child(1),
  .fs-big .slider-description :nth-child(2),
  .fs-big .slider-description :nth-child(3),
  .fs-big .slider-description :nth-child(4),
  .fs-big .slider-description :nth-child(5), 
  .fs-big .slider-description :nth-child(6) {
    display: none;
  }
   

  .fs-small {
    display: flex;
  }

  .footer-stars-container {
    width: 100%;
    padding-bottom: 2%;
  }

  .footer-stars {
    height: 34px;
    padding-top: 2%;
  }

  .footer-text-head,
  .footer-text-descr {
    text-align: center;
  }

  .slider-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    position: relative;
    top: 5px;
  }

  .footer-text-head {
    font-size: 18px;
  }
}

@media screen and (max-width: 595px) {
  .footer-text-block {
    min-height: 240px;
  }
}

@media screen and (max-width: 555px) {
  .footer-text-block {
    min-height: 260px;
  }
}

@media screen and (max-width: 480px) {
  .slider-container {
    background-color: $background-color;
    box-shadow: 0px 0px 30px rgba(33, 37, 46, 0.21);
  }
  // .footer-text-head {
  //   padding-top: 10px;
  //   margin-bottom: 17px;
  // }


  .fead-icon {
    margin-right: 20px;
    margin-left: 10px;   
  }

  .footer-stars {
    height: 67px;
    padding-top: 0;
    display: flex;
    justify-content: center;
  }

  .fs-small {
    height: auto;
    margin-top: 5px;
  }

  .footer-stars-container {
    padding-bottom: 0;
  }

  .slider-header {
    width: 100% !important;
    margin-left: 40px;
  }

 .pagination-my {
   margin-top: 5px;
 }

  .footer-stars .mark-1 {
    font-size: 24px;
  }

  .footer-text-block {
    width: 90%;
  }
  
  .footer-stars .star,
  .footer-stars .star-half,
  .footer-stars .star-empty{
    width: 15.35px;
    height: 15.35px;
    background-size: cover;
  }


  .page-footer {
    background-color: rgba(253, 38, 0, 0.5)
  }

  .page-footer.selected {
    background-color: $red-color;
  }

  .assessment {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // .footer-stars {
  //   height: 46px;
  // }

  .footer-text-descr {
    font-size: 14px;
    line-height: 16px;
    min-height: 105px;
  }

  .footer-review-outer {
    max-height: initial;
  }

  .footer-stars-container {
    margin-bottom: 25px;
  }

  .footer-text-block {
    min-height: auto;
    height: 180px;
  }

  .selected {
    width: 25px;
  }

  .footer-review {
    width: 100%;
  }

}

@media screen and (max-width: 428px) {


}

@media screen and (max-width: 400px) {
  .slider-header {
    margin-left: 20px;
  }

  .footer-text-block {
    min-height: auto;
    height: 200px;
  }
}


@media screen and (max-width: 375px) {
  .footer-review {
    width: 100%;
    top: -42px !important;

  }

  .footer-stars-container {
    margin-top: 24px;
    margin-bottom: 15px;
  }


  .slider-description {
    top: 59px;
    left: 101px;
  }
  .slider-awards {
    justify-content: space-around !important;
    padding: 0;
     margin: 0;
  }

  .footer-review-outer {
    margin-bottom: 0;
  }

  .pagination-my {
    margin-top: 0;
  }

  .slider-header {
    margin-top: 77px;
  }

  .fead-icon {
    width: 74px;
    height: 74px;
  }

  .footer-text-head {
    font-size: 16px;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .footer-text-block {
    min-height: auto;
    height: 180px;
  }

}