$background: url('../../assets/ContactUs/Background.png');
$background-white: #FFFFFF;
$background-black: #21252E;
$text-color: #151515;
$red-color: #D70000; 
$red-color-hover: #FF2B2B;
$red-color-active: #BA0101;
$black-background: #000000;
$error-bg: #B34A4A;
$form-bg: #F5F5F5;



.background-container {
    background-color: $black-background;
    box-shadow: 0px -25px 25px rgba(0, 0, 0, 0.25);
}

.invisibleCaptcha{
    visibility: hidden;
    height: 0;
}

.contact-container {
    background-color: $background-black;
    background-image: $background;
    background-size: contain;
    padding: 115px 0;
    display: flex;
    border-bottom-right-radius: 200px;
}

.form-container {
    background-color: $background-white;
    margin: 0 auto;
    padding: 0 60px;
    border-top-left-radius: 150px;
    border-bottom-right-radius: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-basis: 700px;
    min-height: 910px;
}

.form-title {
    font-size: 38px;
    font-weight: 400;
    letter-spacing: 7px;
    text-transform: uppercase;
    text-align: center;
    padding-top: 75px;
    color: $text-color;
    font-weight: bold;
}

.form-description {
    padding-top: 25px;
    font-size: 20px;
    color: $text-color;
    text-align: center;
    padding-bottom: 40px;
    font-weight: 400;
    width: 400px;
}


.contact-us-tu {
    width: 500px;


    .cu-img-tu {
        width: 70%;
        height: auto;
        margin: auto;
    }
}

.hidden-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-content: center;
   align-items: center;
}

.form-input-holder {
    position: relative;
    margin-bottom: 30px;
    border-radius: 50px !important;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
}

.form-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}



.captcha-container {
    .info-check-invisible {
        display: none;
    }
    .captcha-error {
        background-color: #B34A4A;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        max-width: 99.5%;
        margin-top: -2px;
    }
}

.personal-information {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 474px;
    margin: 30px 0;
    .checkbox-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 26px;
        .info-check {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark-checked {
                display: block;
            }
        }
        .checkmark {
            display: block;
            background: #FFFFFF;
            border: 1px solid #CCCCCC;
            box-sizing: border-box;
            border-radius: 7px;
            width: 28px;
            height: 28px;
            &:hover {
                background-color: #eee;
            }
        }
        .checkmark-checked {
            position: absolute;
            display: none;
        }
    }

    .info-text {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        color: #21252E;
        font-size: 12px;
        line-height: 15px;
        .cookie-policy {
            text-decoration: none;
            color: #0077bc;
            cursor: pointer;
        }   
    }
}

.form-field {
    height: 80px;
    width: 500px;
    text-align: center;
    border-radius: 50px !important;
    border: 0;
    background-color: $form-bg;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;

    &:active, &:focus {
        background-color: $background-white;
        border: 3px solid $form-bg;
    }
}

.form-button {
    box-shadow: none;
    background-color: $red-color !important;
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    display: block;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 31px;
    cursor: pointer;
    -webkit-touch-callout: none; 
      -webkit-user-select: none;
       -khtml-user-select: none; 
         -moz-user-select: none; 
          -ms-user-select: none; 
              user-select: none;

    &:hover {
        background-color: $red-color-hover;
    }

    &:active {
        background-color: $red-color-active;
    }
}

.form-copyright {
    text-align: center;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 300;
}

.form-error {
    position: absolute;
    color: $background-white;
    bottom: 0;
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $error-bg;
    font-size: 14px;
    
}

input:focus::placeholder{
    color:transparent;
}

@media screen and (max-width: 767px) {
    .form-field {
       width: 400px;
    }

    .form-container {
        padding: 0 40px;
    }

    .contact-container {
        border-bottom-right-radius: 70px;
    }

    .contact-us-tu {
        width: 400px;
    }
    
    .personal-information {
        max-width: 370px;
    }

    
}

@media screen and (max-width: 480px) {
    .contact-container {
        border-bottom-right-radius: 0;
        background-image: none;
        padding-top: 30px;
        padding-bottom: 40px;
    }

    .form-container {
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        height: 570px;
    }

    .form-title {
        font-size: 24px;
        letter-spacing: 4px;
    }

    .form-description {
        padding-top: 15px;
        font-size: 18px;
        max-width: 100%;
    }

    // .it-desc {
    //     max-width: 100%;
    // }

    .form-field {
       width: 400px;
       height: 60px;
    }

    .form-button {
        margin-bottom: 50px;
        font-size: 18px;
    }

    .form-input-holder {
        margin-bottom: 15px;
    }

    .form-container {
        padding: 0 20px;
    }

    .contact-us-tu {
        width: 400px;
    }    

    .form-error {
        font-size: 12px;
    }
}

@media screen and (max-width: 445px) {
    .form-container {
        max-width: 335px;
        height: 550px;
    }

    .form-field {
        max-width: 295px;
        height: 55px;
    }

    .form-button {
        font-size: 16px;
    }

    .contact-us-tu {
        width: 335px;
    }    

    .it-desc {
        padding-bottom: 20px;
        max-width: 65%;
    }

    .personal-information {
        max-width: 300px;
        .checkbox-container {
            margin: 0 11px;
        }
    }
}

@media screen and (max-width: 375px) {
    .form-error {
        font-size: 10px;
    }
}

@media screen and (max-width: 341px) {
    .form-field {
        max-width: 280px;
        height: 50px;
    }

    .form-container {
        height: 530px;
    }

    .contact-us-tu {
        width: 320px;
    }    
}

