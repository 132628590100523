$red-color: #FD2600;
$text-color: #818181;
$red-main-color: #D70000;

.container-rv {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 109px;
}

.title-rv {
    display: flex;
    flex-direction: column;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 38px;
    letter-spacing: 7px;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

    .title-separator-rv{
        width: 100px;
        height: 2px;
        margin: 10px 0;
        background-color: $red-main-color;
    }

    .separator-it {
        width: 182px;
    }
}

@media screen and (max-width: 1020px) {

    .container-rv {
        padding-top: 83px;
    }

    .title-rv {
        font-size: 32px;
        line-height: 100%;
    }
}

@media screen and (max-width: 768px) {
    .title-rv {
        font-size: 28px;
        letter-spacing: 4px;


        .title-separator-rv{
            width: 94px;
        }

        .separator-it {
            width: 142px;
        }
    }


}

@media screen and (max-width: 480px) {
    .title-rv {
        font-size: 24px;
        margin-bottom: 60px;


        .title-separator-rv{
            width: 86px;
        }

        .separator-it {
            width: 112px;
        }
    }

    .container-rv {
        padding-top: 35px;
    }



}


@media screen and (max-width: 375px) {
    .title-rv {
        font-size: 20px;


        .title-separator-rv{
            width: 62px;
        }

        .separator-it {
            width: 102px;
        }
    }


}